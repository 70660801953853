import React from "react";
import { Nav, Hero, Footer, Blogs, AboutContact } from "./components";
import { ThemeProvider } from "next-themes";

function App() {
  return (
    <ThemeProvider attribute="class">
      <div className="min-h-screen flex flex-col">
        <Nav />
        <Hero />
        <Blogs />
        <AboutContact />
        <Footer />
      </div>
    </ThemeProvider>
  );
}

export default App;
