import { navLinks } from "../constants";
import { ToggleTheme } from "../assets";
import { useTheme } from "next-themes";

const Nav = () => {
  const { theme, setTheme } = useTheme("dark");

  const toggleTheme = () => {
    setTheme(theme === "dark" ? "light" : "dark");
  };

  const scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <header className="sticky top-0 left-0 z-50 flex items-center justify-center py-4 border-t border-gray-700 bg-white dark:bg-black transition-colors">
      {/* add this div to set the max width of logo and navigation */}
      <div className="flex items-center justify-between w-full max-w-[1440px] px-4">
        {/* logo */}
        <div className="w-[150px]">
          <h2 className="text-xl dark:text-white font-semibold uppercase hover:text-blue-500 cursor-pointer transition-colors">
            THGeek
          </h2>
        </div>

        {/* navigation */}
        <div className="w-[500px]">
          <ul className="flex flex-row items-center justify-end space-x-6">
            {navLinks.map((item, index) => (
              <li
                key={index}
                onClick={() => scrollToSection(item.id)}
                className="text-lg darK:text-white hover:text-blue-500 cursor-pointer transition-colors"
              >
                {item.label}
              </li>
            ))}

            {/* toggle theme */}
            <li
              onClick={toggleTheme}
              className="flex items-center cursor-pointer w-[22px]"
            >
              <img src={ToggleTheme} alt="toggle theme" />
            </li>
          </ul>
        </div>
      </div>
    </header>
  );
};

export default Nav;
