const Hero = () => {
  return (
    <section
      className="relative min-h-screen flex items-start justify-center pb-4 px-4 pt-[30vh] dark:bg-slate-900 bg-white dark:bg-grid-white/[0.2] bg-grid-slate-900/[0.2] transition-colors border-t border-gray-700"
      id="home"
    >
      {/* Radial gradient */}
      <div className="absolute pointer-events-none inset-0 flex items-center justify-center dark:bg-black bg-white [mask-image:radial-gradient(ellipse_at_center,transparent_20%,black)]"></div>

      {/* Container with max-width */}
      <div className="w-full max-w-[1440px] flex items-center justify-center">
        <p className="text-4xl sm:text-7xl font-bold relative z-20 bg-clip-text text-transparent bg-gradient-to-b dark:from-neutral-200 dark:to-neutral-500 from-neutral-800 to-neutral-500 py-8">
          Welcome to my digital garden,
          <br />
          where I share my thoughts, ideas, and technical insights.
        </p>
      </div>
    </section>
  );
};

export default Hero;
