import { Github, Linkedin, Mail } from "lucide-react";
import LeetCodeIcon from "../components/LeetCodeIcon";

export const icpCode = "渝ICP备20003158号";

export const navLinks = [
  { label: "Home", id: "home" },
  { label: "Blog", id: "blog" },
  { label: "About", id: "about" },
  { label: "Contact", id: "contact" },
];

export const personalInfo = {
  name: "Tao Hong",
  title: "Java / Full Stack Engineer",
  specialization: "Java & Web Technologies",
  bio: "Passionate Full Stack Developer with expertise in Java backend development and modern web technologies. Focused on building scalable, efficient applications with clean code practices.",
};

export const skills = {
  backend: [
    "Java",
    "Spring Boot",
    "Spring Cloud",
    "Golang",
    "MySQL",
    "MongoDB",
  ],
  frontend: ["React", "JavaScript", "HTML5", "CSS3"],
  tools: ["Git", "Docker", "Jenkins", "AWS", "Maven"],
};

export const currentProjects = [
  {
    company: "PandaBox.ai (bettertext.cn)",
    position: "Architect / Full Stack Engineer",
    period: "2024 - Present",
    description:
      "All-in-One AI assistant with seamless access to leading Chinese LLMs, including Doubao, Qwen, Kling, etc.",
  },
];

export const socialLinks = [
  {
    Icon: Mail,
    href: "mailto:alex24ht@hotmail.com",
    isEmail: true,
  },
  {
    Icon: Github,
    href: "https://github.com/thgeek24",
    isEmail: false,
  },
  {
    Icon: Linkedin,
    href: "https://www.linkedin.com/in/tao-hong-uk",
    isEmail: false,
  },
  {
    Icon: LeetCodeIcon,
    href: "https://leetcode.com/u/THGeek/",
    isEmail: false,
  },
];

export const blogSamples = [
  {
    id: 1,
    title: "Java Memory Model: Deep Dive into Concurrency",
    date: "November 28, 2024",
    description:
      "Exploring the Java Memory Model, happens-before relationships, and their impact on concurrent programming.",
  },
  {
    id: 2,
    title: "Understanding Java Virtual Threads",
    date: "December 15, 2024",
    description:
      "An in-depth look at the new virtual threads feature in Java 21 and how it can improve application performance.",
  },
  {
    id: 3,
    title: "JVM Architecture and Garbage Collection",
    date: "December 30, 2024",
    description:
      "A comprehensive guide to JVM internals, memory management, and modern garbage collection algorithms.",
  },
  {
    id: 4,
    title: "MVCC in Java: Database Concurrency Control",
    date: "January 5, 2025",
    description:
      "Understanding Multi-Version Concurrency Control implementation in Java-based database systems.",
  },
];
