import React from "react";
import { Copyright } from "lucide-react";
import { icpCode } from "../constants";

function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <>
      {/* Copyright Footer */}
      <footer className="mt-auto p-4 dark:bg-black transition-colors">
        <div className="flex items-center justify-center dark:text-gray-400 text-sm transition-colors">
          <Copyright className="w-4 h-4 mr-2" />
          <span>
            2024-{currentYear} thgeek.com. All rights reserved. {icpCode}
          </span>
        </div>
      </footer>
    </>
  );
}

export default Footer;
