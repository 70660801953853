import { useState, useEffect } from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { blogSamples } from "../constants";

const Blogs = () => {
  const [markdownContent, setMarkdownContent] = useState("");

  useEffect(() => {
    // Fetch your markdown content here
    fetch("./welcome.md")
      .then((res) => res.text())
      .then((text) => setMarkdownContent(text));
  }, []);

  return (
    <section
      className="relative min-h-screen flex items-start justify-center p-4 bg-white dark:bg-slate-900 border-t border-gray-700 transition-colors"
      id="blog"
    >
      <div className="w-full max-w-[1440px] mx-auto p-4 mt-16">
        {/* Section title */}
        <h2 className="section-title" id="blog">
          Recent Blogs
        </h2>
        {/* Welecome message */}
        <ReactMarkdown children={markdownContent} remarkPlugins={[remarkGfm]} />
        {/* Blog posts */}
        <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3 mt-8">
          {blogSamples.map((post) => (
            <article
              key={post.id}
              className="flex flex-col border border-gray-100 rounded-lg p-6 hover:shadow-lg transition-shadow h-full"
            >
              <div className="text-sm text-gray-500 mb-4">{post.date}</div>
              <h2 className="text-xl font-semibold mb-2">{post.title}</h2>
              <p className="text-gray-600 mb-4 flex-grow">{post.description}</p>
              <a className="text-blue-500 hover:text-blue-600 mt-auto cursor-pointer">
                To be continued...
              </a>
            </article>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Blogs;
