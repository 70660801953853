import { socialLinks } from "../constants";

const AboutContact = () => {
  return (
    <section className="min-h-screen relative flex items-center justify-center p-4 bg-white dark:bg-black border-t border-gray-700 transition-colors">
      <div className="w-full max-w-[1440px] mx-auto px-4 py-8 flex sm:flex-row flex-col items-center justify-around">
        {/* About Me */}
        <div className="w-[500px] h-[500px]" id="about">
          <h2 className="section-title">About Me</h2>
          <p className="text-lg dark:text-neutral-200 text-gray-800">
            Hi, there!
            <br />
            I'm a Java and full-stack developer passionate about coding,
            algorithms, and AI. Beyond tech, I love exploring creativity through
            photography, cooking, and global travel. My journey blends
            problem-solving with a curiosity for diverse cultures and
            experiences.
          </p>
        </div>

        {/* Contact*/}
        <div className="w-[500px] h-[500px]" id="contact">
          <h2 className="section-title">Contact</h2>
          <ul className="flex space-x-4">
            {socialLinks.map(({ Icon, href, isEmail }, index) => (
              <a
                key={index}
                href={href}
                target={isEmail ? "_self" : "_blank"}
                rel={!isEmail ? "noopener noreferrer" : undefined}
              >
                <Icon className="w-6 h-6 cursor-pointer transform transition-all duration-300 hover:scale-110 dark:text-gray-400 text-gray-600 dark:hover:text-white hover:text-black" />
              </a>
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
};

export default AboutContact;
